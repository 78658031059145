.video-container {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
}
.video-container img {
    height: 100%;
    max-width: 100%;
}
.text-layer{
    position: absolute;
}